/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useContext } from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import { DocumentContext } from "~context/DocumentContext";
import Footer from "~components/Footer";
import ImageCTA from "~components/ImageCTA";
import Layout from "~components/Layout";
import Parallaxer from "~components/Parallaxer";
import ProductGrid from "~components/ProductGrid";
import SEO from "~components/SEO";
import { parseProducts } from "~utils/shopify";

const IndexPage = ({ data, location }) => {
  const { device } = useContext(DocumentContext);

  //

  const { frontmatter } = data.markdownRemark;

  const products = parseProducts(data);

  return (
    <>
      <SEO
        customTitle={frontmatter.title}
        customDescription={frontmatter.seoDescription}
        customKeywords={frontmatter.seoKeywords}
        path={location.pathname}
      />

      <Layout className="index-page w-full relative">
        <section className="index-page__banner animation-appear-down-slow animation-delay-2 w-full relative pt-20 overflow-hidden">
          <div className="grid z-10">
            <div className="grid-end-5 sm:grid-end-8 xs:grid-end-12 mt-16 xs:mt-12 text-white">
              <h2 className="index-page__banner__subheading animation-appear-down-slow animation-delay-3 b1 text-white">
                {frontmatter.bannerSubheading}
              </h2>

              <h1 className="index-page__banner__heading animation-appear-down-slow animation-delay-4 f3 text-white">
                {device === `desktop`
                  ? frontmatter.bannerHeading
                  : frontmatter.bannerHeadingXS}
              </h1>

              <Link
                to={frontmatter.bannerCollectionTarget}
                className="animation-appear-down-slow animation-delay-5 block mt-8 b1 spaced underline text-white"
              >
                {frontmatter.bannerCollectionText}
              </Link>
            </div>

            <div className="desktop-only absolute top-0 right-0 mt-16">
              {[`お`, `茶`].map((char, charIndex) => (
                <span
                  key={char}
                  className="animation-appear-down-slow block jappas text-white"
                  style={{ animationDelay: `${charIndex * 100 + 500}ms` }}
                >
                  {char}
                </span>
              ))}
            </div>
          </div>

          <Parallaxer
            atTop
            className="h-full absolute top-0 right-0 left-0 bottom-0 flex items-end justify-center"
            reverse
            severe
          >
            {device &&
              device === `desktop` &&
              frontmatter?.bannerImage?.childImageSharp?.fluid && (
                <Img
                  className="w-full h-full relative index-page__banner__image "
                  fluid={frontmatter.bannerImage.childImageSharp.fluid}
                  alt={frontmatter.bannerImageAlt}
                />
              )}

            {device &&
              device !== `desktop` &&
              frontmatter?.bannerImageXS?.childImageSharp?.fluid && (
                <Img
                className="w-full h-full relative object-cover"
                fluid={frontmatter.bannerImageXS.childImageSharp.fluid}
                  alt={frontmatter.bannerImageAlt}
              />
              )}
          </Parallaxer>
        </section>

        <ProductGrid
          collection="Japanese Green Tea"
          heading="Shop Tea"
          max={3}
          products={products}
        />

        <ul className="xs:pb-12">
          {frontmatter.articleBlocks.map((articleBlock, articleBlockIndex) => {
            const key = `article-block-${articleBlockIndex}`;

            return (
              <li
                key={key}
                className={`${
                  articleBlockIndex === 0 ? `pt-32 border-t-black` : ``
                } ${
                  articleBlockIndex === frontmatter.articleBlocks.length - 1
                    ? `pb-32`
                    : ``
                } xs:pt-0 xs:pb-12`}
              >
                <ImageCTA
                  align={articleBlock.align}
                  content={articleBlock.content}
                  heading={articleBlock.heading}
                  image={articleBlock.image}
                  subheading={articleBlock.subheading}
                  linkText={articleBlock.linkText}
                  linkTo={articleBlock.linkTo}
                />
              </li>
            );
          })}
        </ul>

        <ProductGrid
          collection="Teaware"
          heading="Shop Teaware"
          max={3}
          products={products}
        />

        {/* <Newsletter /> */}
      </Layout>

      <Footer />
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        bannerSubheading
        bannerHeading
        bannerHeadingXS
        bannerCollectionText
        bannerCollectionTarget
        bannerImage {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        bannerImageXS {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        bannerImageAlt
        articleBlocks {
          align
          image {
            childImageSharp {
              fluid(maxWidth: 1280, quality: 75) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
            publicURL
          }
          heading
          subheading
          content
          linkText
          linkTo
        }
        seoDescription
        seoKeywords
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "product-page" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            shopifyHandle
            priority
            collection
            title
            image {
              childImageSharp {
                fluid(maxWidth: 1280, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
    allShopifyProduct {
      edges {
        node {
          id
          title
          description
          handle
          images {
            originalSrc
          }
          productType
          vendor
          variants {
            id
            title
            image {
              originalSrc
            }
            price
            selectedOptions {
              name
              value
            }
          }
        }
      }
    }
    allShopifyAdminProduct {
      edges {
        node {
          products {
            handle
            variants {
              alternative_id
            }
          }
        }
      }
    }
  }
`;
