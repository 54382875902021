import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import PropTypes from "prop-types";

const ProductGrid = ({
  className,
  collection,
  compact,
  heading,
  large,
  max,
  noborder,
  products
}) => {
  const productsClone = JSON.parse(JSON.stringify(products));

  let visibleProducts = [];

  if (collection) {
    productsClone.forEach(product => {
      if (
        product.frontmatter.collection.toLowerCase() ===
        collection.toLowerCase()
      ) {
        visibleProducts.push(product);
      }
    });
  } else {
    visibleProducts = productsClone;
  }

  if (max) {
    visibleProducts = visibleProducts.slice(0, max);
  }

  //

  return (
    <section
      className={`product-grid ${className} w-full relative mb-12 pb-8 ${
        noborder ? `` : `border-t-black`
      } ${heading ? `` : `mt-24 xs:mt-16`}`}
    >
      {heading && (
        <h2 className="product-grid__heading w-full relative f4 text-center">
          {heading}
        </h2>
      )}

      {visibleProducts?.[0] && (
        <ul className="grid">
          {visibleProducts.map((product, productIndex) => {
            let image = product.images[0].originalSrc;

            if (product?.frontmatter?.image?.childImageSharp) {
              image = product.frontmatter.image.childImageSharp;
            }

            let gridClasses = `grid-end-4`;

            if (large) {
              gridClasses = `grid-end-5 ${
                productIndex % 2 === 0 ? `grid-start-2` : ``
              }`;
            }

            const hasVariants = product?.variants?.[1];
            let lowestVariantPrice;

            if (hasVariants) {
              product.variants.forEach(variant => {
                if (
                  !lowestVariantPrice ||
                  (variant.price !== 0 && lowestVariantPrice > variant.price)
                ) {
                  lowestVariantPrice = variant.price;
                }
              });
            }

            //

            return (
              <li
                key={product.handle}
                className={`product-grid__item ${gridClasses} ${
                  compact
                    ? `product-grid__item--compact sm:grid-end-6`
                    : `sm:grid-end-10 sm:grid-start-2 xs:grid-start-1`
                } xs:grid-end-12 mb-12 hover-underline`}
              >
                <Link to={product.slug}>
                  <div className="square overflow-hidden gpu">
                    <div className="product-grid__item__overlay w-full h-full absolute top-0 right-0 bottom-0 left-0 z-10 flex items-center justify-center">
                      <h4 className="block relative b2 uppercase text-black">
                        View Product
                      </h4>
                    </div>

                    {(image?.fluid && (
                      <figure className="w-full absolute transform-center">
                        <Img
                          className="w-full relative block"
                          fluid={image.fluid}
                          alt={product.frontmatter.title}
                        />
                      </figure>
                    )) || (
                      <figure className="w-full absolute transform-center">
                        <img
                          className="w-full relative block"
                          src={product.images[0].originalSrc}
                          alt={product.handle}
                        />
                      </figure>
                    )}
                  </div>

                  <div className="pt-3">
                    <h2 className="b1 spaced font-medium">
                      {product.frontmatter.title}
                    </h2>
                    <h2 className="b1 spaced">
                      {hasVariants
                        ? `From $${lowestVariantPrice}`
                        : `$${product.variants[0].price}`}
                    </h2>
                  </div>
                </Link>
              </li>
            );
          })}
        </ul>
      )}
    </section>
  );
};

ProductGrid.defaultProps = {
  className: ``,
  collection: null,
  compact: false,
  heading: null,
  large: false,
  noborder: false,
  max: null
};

ProductGrid.propTypes = {
  className: PropTypes.string,
  collection: PropTypes.string,
  compact: PropTypes.bool,
  heading: PropTypes.string,
  large: PropTypes.bool,
  noborder: PropTypes.bool,
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  max: PropTypes.number
};

export default ProductGrid;
