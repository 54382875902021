import React, { useContext } from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import { Link } from "gatsby";
import { DocumentContext } from "~context/DocumentContext";

const ImageCTA = ({
  align,
  className,
  content,
  grid,
  heading,
  image,
  linkText,
  linkTo,
  subheading
}) => {
  const { device } = useContext(DocumentContext);

  const ctaJSX = (
    <>
      <h3 className="b1">{subheading}</h3>
      <h2
        className={`mt-6 ${
          device === `desktop` ? `f3` : `f1`
        } whitespace-pre-wrap`}
      >
        {heading}
      </h2>
      <p className="mt-10 whitespace-pre-wrap b1">{content}</p>

      <Link
        to={linkTo}
        className="inline-block mt-16 b1 spaced underline font-medium text-black"
      >
        {linkText}
      </Link>
    </>
  );

  return (
    <section className={`${className} image-cta w-full relative`}>
      {(grid && (
        <article className="grid">
          {((device && device === `mobile`) || align === `left`) && (
            <div className="grid-end-6 sm:grid-end-12 relative">
              {image?.childImageSharp?.fluid && (
                <Img
                  className="w-full h-full xs:h-auto absolute xs:relative transform-center"
                  fluid={image.childImageSharp.fluid}
                  alt="Source"
                />
              )}
            </div>
          )}

          <div className="grid-end-6 sm:grid-end-12">{ctaJSX}</div>

          {device !== `mobile` && align === `right` && (
            <div className="grid-end-6 sm:grid-end-12 relative">
              <Img
                className="w-full h-full absolute transform-center"
                fluid={image.childImageSharp.fluid}
                alt="Source"
              />
            </div>
          )}
        </article>
      )) || (
        <article className="w-full relative flex xs:flex-col">
          {((device && device === `mobile`) || align === `left`) && (
            <div className="image-cta__image relative">
              <Img
                className="w-full h-full absolute transform-center"
                fluid={image.childImageSharp.fluid}
                alt="Source"
              />
            </div>
          )}

          <div
            className={`image-cta__content relative ${
              align === `right` ? `pt-10 pr-v10 pl-v5` : `pt-24 pr-v6 pl-v6`
            } xs:pt-12`}
          >
            {ctaJSX}
          </div>

          {device !== `mobile` && align === `right` && (
            <div className="image-cta__image relative">
              <Img
                className="w-full h-full absolute transform-center"
                fluid={image.childImageSharp.fluid}
                alt="Source"
              />
            </div>
          )}
        </article>
      )}
    </section>
  );
};

ImageCTA.defaultProps = {
  align: `left`,
  className: ``,
  content: ``,
  grid: false,
  linkText: ``,
  linkTo: ``,
  heading: ``,
  subheading: ``
};

ImageCTA.propTypes = {
  align: PropTypes.string,
  className: PropTypes.string,
  content: PropTypes.string,
  grid: PropTypes.bool,
  heading: PropTypes.string,
  image: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.shape({}).isRequired
    })
  }).isRequired,
  linkText: PropTypes.string,
  linkTo: PropTypes.string,
  subheading: PropTypes.string
};

export default ImageCTA;
